// Avatar
.avatar {
  position: relative;
  display: inline-block;
  width: 3rem;
  height: 3rem;
}
// Avatar Size
.avatar-xs {
  width: 1.5rem;
  height: 1.5rem;
}
.avatar-xxl {
  width: 7rem;
  height: 7rem;
}
