// Hero

.hero-agency {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    min-height: 768px;
    @media (max-width: 768px) {
        min-height: 500px;
    }
}

// Hero for IT Homepage
video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}
