// Gallery

.gallery {
    .gallery-item {
        width: calc(100% / 2);
        padding: 15px;
        @media (max-width: 991px) {
            width: 50%;
        }
        @media (max-width: 767px) {
            width: 100%;

            .gallery-filter {
                .filter-item {
                    margin-bottom: 10px;
                }
            }
        }
    }
    .gallery-item.show {
        animation: fadeIn 0.5s ease;
        transition: all 0.5s ease;
    }
    .gallery-item.hide {
        display: none;
    }
}

// For Effect
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
