@use 'colors' as *;
@use 'typography' as *;
:root {
    //@Font Family Declaration
    @each $font-family, $shades in $theme-fonts {
        @each $shade, $value in $shades {
            --tc-#{$font-family}-#{$shade}: #{$value};
        }
    }
    //@Font Size
    @each $body, $shades in $font-size {
        @each $shade, $value in $shades {
            --tc-#{$body}-#{$shade}: #{$value};
        }
    }
    //@Line Height
    @each $line-height, $shades in $theme-lh {
        @each $shade, $value in $shades {
            --tc-#{$line-height}-#{$shade}: #{$value};
        }
    }
    //@Color Declaration
    @each $color, $shades in $colors {
        @each $shade, $value in $shades {
            --tc-#{$color}-#{$shade}: #{$value};
        }
    }
    //@Font Weight Declaration
    @each $fw, $shades in $font-weight {
        @each $shade, $value in $shades {
            --tc-#{$fw}-#{$shade}: #{$value};
        }
    }
}
