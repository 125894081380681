// Transitions
// Image zoom
.zoom-img {
    transform: translateZ(0);
    overflow: hidden;
    & img {
        display: block;
        transition: transform 0.35s ease-in-out;
    }
    &:hover img {
        transform: scale(1.05);
    }
}
// Lift
.lift {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    transform: translateY(0);
    transition: all 0.3s ease;
    border-radius: $border-radius-lg;
    &:hover {
        transform: translateY(-0.4rem);
        box-shadow: 0 0.25rem 1.25rem rgba(52, 63, 82, 0.1);
    }
}
.img-opacity {
    ::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        opacity: 0;
        transition: opacity 0.35s ease;
    }
    &:hover::after {
        opacity: 1;
    }
}
