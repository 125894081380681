// Card
// Card Lift
.card-lift {
    transition:
        transform 0.2s ease-in-out,
        border-color 0.2s ease-in-out,
        background-color 0.2s ease-in-out,
        box-shadow 0.2s ease-in-out;
    border-radius: $border-radius-lg;
    &:hover,
    &:focus,
    &.active {
        transform: translateY(-0.25rem);
        box-shadow:
            0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
    }
}
// Card Primary
.card-primary {
    background-color: var(--tc-neutral-100);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    .card-title {
        color: var(--tc-neutral-800);
    }
    &:hover {
        background-color: var(--tc-theme-primary);
        .card-title {
            color: var(--tc-neutral-0);
        }
        .card-text {
            color: var(--tc-neutral-100);
        }
        .card-link {
            color: var(--tc-neutral-0);
        }
    }
}
.outstanding {
    cursor: pointer;
    background-image: var(--tc-gradient-1) !important;
    box-shadow: 0px 16px 32px 0px rgba(249, 71, 67, 0.3);
    border: none !important;
    .card-title {
        color: var(--tc-neutral-0);
        -webkit-text-fill-color: white;
    }
    .card-text {
        color: var(--tc-neutral-0) !important;
        -webkit-text-fill-color: white;
    }
    .card-link {
        background-color: var(--tc-neutral-1000);
        color: var(--tc-neutral-0);
        border-color: var(--tc-neutral-1000);
    }
    .strip {
        display: block !important;
    }
}
// Card hover
.card-hover {
    transition: 0.3s ease-in-out;
    box-shadow: 0.2s ease-in-out;
    cursor: pointer;
    display: block;
    & svg {
        fill: var(--tc-neutral-400);
    }
    .ic-arrow i {
        transition: all 0.2s;
    }
    &:hover {
        transform: translateY(-0.25rem);
        box-shadow:
            0 10px 15px -3px rgba(0, 0, 0, 0.1),
            0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
        span {
            border-color: var(--tc-theme-primary) !important;
        }
        .card-text {
            color: var(--tc-theme-primary);
        }
        & svg {
            fill: var(--tc-theme-primary);
        }
        .ic-arrow i {
            transform: rotate(-45deg);
            transition: all 0.2s;
        }
    }
}
// Card Grid
.card-grid {
    border-top: 2px solid var(--tc-neutral-200);
    position: relative;
    display: flex;
    justify-content: space-between;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 100% 100%;
    transition: all 0.3s ease;
    height: 230px;
    cursor: pointer;
    .card-grid-inner {
        width: 100%;
        position: absolute;
        padding: 24px;
        background: none;
        transition: all 0.3s ease;
        z-index: 2;
    }
    @media (min-width: 990px) {
        .card-grid-heading {
            max-width: 50%;
        }
    }
    .card-grid-text {
        transition: all 0.3s ease;
        opacity: 0;
        visibility: hidden;
    }
    .card-grid-image {
        position: absolute;
        right: 0;
        width: 210px;
        height: auto;
        margin: 24px 0px;
    }
    @media (min-width: 990px) {
        &:hover {
            border-top-color: var(--tc-theme-primary);
            border-width: 2px;
            z-index: 3;
            .card-grid-inner {
                background-color: var(--tc-neutral-0);
                box-shadow: $box-shadow;
            }
            .card-grid-image {
                opacity: 0;
                visibility: hidden;
            }
            .card-grid-text {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
@media (max-width: 990px) {
    .card-grid {
        height: auto;
        .card-grid-inner {
            position: relative;
        }
        .card-grid-image {
            opacity: 0;
            visibility: hidden;
            display: none;
        }
        .card-grid-text {
            opacity: 0;
            visibility: hidden;
            display: none;
        }
    }
}
.clients {
    box-shadow: 0px 10px 15px 0px rgba(0, 0, 0, 0.06);
    transition-duration: 0.3s;
    :hover {
        border-color: var(--tc-neutral-500) !important;
        transition-duration: 0.3s;
    }
    .client-bg {
        top: 5%;
        right: 5%;
    }
}
