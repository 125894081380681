/*=============================
Preloader
===============================*/
#preloader {
    height: 100%;
    width: 100%;
    position: fixed;
    margin-top: 0px;
    top: 0px;
    z-index: 9999;
    background-color: #fff;
    & .loader {
        & .loader-container {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100px;
            height: 100px;
            border: 3px solid #fdf2f9;
            border-radius: 50%;
            &::before {
                position: absolute;
                content: "";
                display: block;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 100px;
                height: 100px;
                border-top: 3px solid var(--tc-theme-primary);
                border-radius: 50%;
                animation: loaderspin 1.8s infinite ease-in-out;
                -webkit-animation: loaderspin 1.8s infinite ease-in-out;
            }
        }
        & .loader-icon {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 80px;
            text-align: center;
            & img {
                animation: loaderpulse alternate 900ms infinite;
                width: 40px;
            }
        }
    }
    &.black-bg,
    &.black2-bg {
      .loader-container {
        border: 3px solid #1a1a1a;
      }
      .loader-icon {
        filter: brightness(99) grayscale(9);
      }
    }
}
@keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@-webkit-keyframes loaderspin {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
@keyframes loaderpulse {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2);
  }
}